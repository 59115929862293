import { Spinner, ToastIntent } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { OffersAPI, PdfUploadDealData } from "api/offers";
import { authMethod } from "auth";
import {
  AccordionComp,
  FoldableItem,
} from "components/accordion/BootstrapAccordion";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { decodeHtmlEntity } from "libs/decode-html-entity";
import { Appendix } from "models/offer/CustomerDeal";
import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import { fetchDeal } from "state/offer/offersThunks";
import { AttachmentPdf, FileUploadStatus } from "../AttachmentPdf";
import OfferTeam from "../OfferTeam";

interface ContractTextsProps {
  contractTexts: Appendix[];
}

function ContractTexts({ contractTexts }: ContractTextsProps) {
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState(FileUploadStatus.INITIAL);
  const [pdfUploadData, setPdfUploadData] = useState<PdfUploadDealData>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const offerCustomer = currentOffer?.customer;

  const handleFileError = () => {
    setStatus(FileUploadStatus.FAIL);
  };

  const fetchDealUploadAttachmentUrl = useCallback(async () => {
    if (currentOffer?.id) {
      const token = await authMethod.getStoredAccessToken();
      setIsLoading(true);
      const tempUploadData = await OffersAPI.fetchDealAttachmentUploadUrl(
        token,
        currentOffer.id
      );

      setIsLoading(false);
      return tempUploadData;
    }
  }, [currentOffer?.id]);

  useEffect(() => {
    const fetchAndSetDealAttachmentUploadUrl = async () => {
      const tempUploadData = await fetchDealUploadAttachmentUrl();
      setPdfUploadData(tempUploadData);
    };
    fetchAndSetDealAttachmentUploadUrl();
  }, [fetchDealUploadAttachmentUrl, currentOffer?.id]);

  const setStatusAndToastMessage = (
    uploadStatus: FileUploadStatus,
    messageKey: TranslationKey,
    messageType: ToastIntent
  ) => {
    setStatus(uploadStatus);
    dispatch(appendToastMessage(messageKey, messageType));
  };

  const handleFileUpload = async (file: File) => {
    try {
      if (currentOffer?.id && pdfUploadData) {
        setStatus(FileUploadStatus.FILE_UPLOADING);
        const response = await OffersAPI.uploadFileToAws(pdfUploadData, file);
        if (response.ok) {
          if (offerCustomer) {
            await dispatch(
              fetchDeal({
                dealId: currentOffer.id,
                orgId: offerCustomer.org_number,
              })
            );
          }
          setStatusAndToastMessage(
            FileUploadStatus.SUCCESS,
            "UPLOAD_ATTACHMENT_SUCCESS",
            "success"
          );
        } else {
          setStatusAndToastMessage(
            FileUploadStatus.FAIL,
            "SOMETHING_WENT_WRONG",
            "error"
          );
        }
      }
    } catch (e) {
      setStatusAndToastMessage(
        FileUploadStatus.FAIL,
        "SOMETHING_WENT_WRONG",
        "error"
      );
    }
  };

  const handleFileDelete = async () => {
    if (currentOffer?.id) {
      try {
        const token = await authMethod.getStoredAccessToken();
        setStatus(FileUploadStatus.FILE_UPLOADING);
        await OffersAPI.deleteDealAttachment(token, currentOffer.id);
        if (offerCustomer) {
          await dispatch(
            fetchDeal({
              dealId: currentOffer.id,
              orgId: offerCustomer.org_number,
            })
          );
        }

        setStatusAndToastMessage(
          FileUploadStatus.SUCCESS,
          "DELETE_ATTACHMENT_SUCCESS",
          "success"
        );
      } catch (e) {
        setStatusAndToastMessage(
          FileUploadStatus.FAIL,
          "SOMETHING_WENT_WRONG",
          "error"
        );
      }
    }
  };

  const getAdditionalAttachmentFilename = () => {
    if (pdfUploadData) {
      const filenameItems = pdfUploadData.fields.key.split("/");
      return filenameItems[filenameItems.length - 1];
    }
    return "";
  };

  function getFoldableItemsFromTexts(): FoldableItem[] {
    const contractItems = contractTexts.map((appendix) => {
      const title = appendix.appendix_title;
      const textsInAppendix = appendix.contract_texts
        .flatMap((ct) => ct.header_swe)
        .join("   ");
      return {
        title: (
          <Row className="w-100">
            <Col md={3}>
              <div className="sub-h1 fw-semibold">{title}</div>
            </Col>
            <Col>
              <div className="body-light">{textsInAppendix}</div>
            </Col>
          </Row>
        ),
        content: appendix.contract_texts.map((contractText) => (
          <Row>
            <Col md={{ span: 9, offset: 3 }}>
              <div
                key={`${contractText.header_swe}-${contractText.id}`}
                className="pr-lg"
              >
                <div className="header-title pb-sm">
                  <div className="sub-h1 fw-bold">
                    {contractText.header_swe}
                  </div>
                </div>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlEntity(contractText.content_swe),
                  }}
                />
              </div>
            </Col>
          </Row>
        )),
        key: title,
      };
    });

    const teamItem = {
      key: "offerTeam",
      title: (
        <Row className="w-100">
          <Col md={3}>
            <div className="sub-h1 fw-semibold">{translate("TEAM")}</div>
          </Col>
          <Col>
            <div className="body-light">
              <span>{translate("SALESPERSONS")}</span>{" "}
              <span className="pl-lg"> {translate("PROJECT_MANAGERS")} </span>
            </div>
          </Col>
        </Row>
      ),
      content: [
        <Row key="offerTeam">
          <Col md={{ span: 9, offset: 3 }}>
            <OfferTeam />
          </Col>
        </Row>,
      ],
    };

    const renderAdditionalPdfContent = () => {
      if (isLoading) {
        return <Spinner size={SpinnerSize.ExtraSmall} />;
      }

      if (currentOffer?.has_attachment) {
        return getAdditionalAttachmentFilename();
      }

      return translate("CREATE_NEW.CONTACT.SUMMARY.NO_ADDITIONAL_PDF");
    };

    const additionalPdfItem = {
      key: "additionalPdf",
      title: (
        <Row className="w-100">
          <Col md={3}>
            <div className="sub-h1 fw-semibold">
              {translate("ADDITIONAL_PDF_ATTACHMENT")}
            </div>
          </Col>
          <Col>
            <div className="body-light">
              <span className={currentOffer?.has_attachment ? "" : "fs-italic"}>
                {renderAdditionalPdfContent()}
              </span>
            </div>
          </Col>
        </Row>
      ),
      content: [
        <Row key="additionalPdf">
          <Col md={{ span: 9, offset: 3 }}>
            {currentOffer?.id && (
              <AttachmentPdf
                onFileUpload={handleFileUpload}
                onFileDelete={handleFileDelete}
                onError={handleFileError}
                status={status}
                dealId={currentOffer.id}
                hasAttachment={currentOffer?.has_attachment}
                pdfUploadData={pdfUploadData}
              />
            )}
          </Col>
        </Row>,
      ],
    };

    return [...contractItems, teamItem, additionalPdfItem];
  }

  return <AccordionComp foldableItems={getFoldableItemsFromTexts()} />;
}

export default ContractTexts;
