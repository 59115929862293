import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import { isDealEditable } from "libs/customerdeal-helpers";
import { getOnlyNumbers, numberAsSwedishCurrency } from "libs/number-format";
import { removeWhiteSpace } from "libs/remove-whitespace-from-strings";
import { useServiceMatrix } from "libs/service-matrix";
import { ServiceCategory } from "models/offer/ServiceLine";
import { RootState } from "state";
import {
  saveServiceCategoryDiscount,
  saveServiceCategoryDiscountComment,
  saveServiceCategoryPriceAdjustment,
} from "state/offer/offersThunks";
import { SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED } from "views/createNew/offer/wizard/consts/offer-contst";
import { SERVICE_GROUP_CURRENT_ACCOUNTING } from "views/createNew/offer/wizard/CurrentAccounting";
import "./FinalPrice.scss";

// generic form interface
type IFormFields = {
  [key: string]: any;
};

export default function FinalPrice() {
  const { translate, ts } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentOffer: { data: currentOffer },
  } = useSelector((state: RootState) => state.offers);

  const {
    GetTotalSuggestedPrice,
    GetTotalAdjustments,
    GetTotalDiscount,
    GetCalculatedPrice,
    GetFinalPrice,
    GetMinPriceAdjustment,
  } = useServiceMatrix();

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.currentOffer?.data?.service_areas
  );
  const offerLoadingStatus = useSelector(
    (state: RootState) => state.offers.currentOffer.status
  );

  const [showModalContent, setShowModalContent] = useState("");

  const {
    register,
    getValues,
    trigger: triggerValidation,
    formState: { errors },
    setValue,
  } = useForm<IFormFields>({ mode: "all" });

  const serviceCategories = useMemo(
    () =>
      serviceAreas
        ?.flatMap((sa) => sa.service_lines)
        .flatMap((sl) => sl.service_groups)
        .flatMap((sg) => sg.service_categories) ?? [],
    [serviceAreas]
  );

  useEffect(() => {
    serviceAreas?.forEach((sa) => {
      sa.service_lines.forEach((sl) => {
        sl.service_groups.forEach((sg) => {
          sg.service_categories.forEach((sc) => {
            const registerRootNamespace = removeWhiteSpace(sl.name);
            const registerGroupNamespace = removeWhiteSpace(sg.name);
            const registerCategoryNamespace = removeWhiteSpace(sc.name);
            const regString = `${registerRootNamespace}.${registerGroupNamespace}.${registerCategoryNamespace}.priceAdjustment`;

            if (sc.price_adjustment !== undefined) {
              setValue(regString, sc.price_adjustment, {
                shouldValidate: true,
              });
            }
            // set min_price_adjustment as price adjustment
            // only if there is no price_adjustment on category level
            // and min_price_adjustment is > 0
            if (
              !sc.price_adjustment &&
              sc.name === SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED &&
              sc.price_prerequisite &&
              sc.price_prerequisite.min_price_adjustment > 0
            ) {
              setValue(regString, sc.price_prerequisite.min_price_adjustment, {
                shouldValidate: true,
              });
            }
          });
        });
      });
    });
  }, [serviceAreas, setValue]);

  if (!serviceAreas) {
    return <>Missing serviceAreas</>;
  }

  if (!serviceCategories) {
    throw new Error("No service categories found");
  }

  const updateServiceCategoryPriceAdjustment = async (
    registerString: string,
    serviceCategory: ServiceCategory
  ) => {
    const isFieldValid = await triggerValidation(registerString);
    if (isFieldValid) {
      const value = getValues(registerString);
      dispatch(
        saveServiceCategoryPriceAdjustment({
          serviceCategory,
          value,
          serviceAreas,
        })
      );
    }
  };

  const updateServiceCategoryDiscount = async (
    registerString: string,
    serviceCategory: ServiceCategory
  ) => {
    const isFieldValid =
      (await triggerValidation(registerString)) &&
      (await triggerValidation(`${registerString}_comment`));
    if (isFieldValid) {
      const value = getValues(registerString);
      const comment = getValues(`${registerString}_comment`);
      if (comment === "" && value > 0) {
        setShowModalContent(
          translate("DISCOUNT_COMMENT_REQUIRED", [
            numberAsSwedishCurrency(value),
          ])
        );
        return;
      }
      dispatch(
        saveServiceCategoryDiscount({ serviceCategory, value, serviceAreas })
      );
    }
  };

  const updateServiceCategoryDiscountComment = (
    registerString: string,
    serviceCategory: ServiceCategory
  ) => {
    const comment = getValues(registerString) ?? "";
    const rootRegisterString = registerString.split(".").slice(0, -1);
    const discountRegisterString = `${rootRegisterString.join(".")}.discount`;
    const discount = getValues(discountRegisterString);

    dispatch(
      saveServiceCategoryDiscountComment({
        comment,
        discount,
        serviceCategory,
        serviceAreas,
      })
    );
  };

  const renderPriceAdjustmentServiceRow = (
    serviceCategory: ServiceCategory,
    serviceGroupName: string,
    serviceLineName: string
  ) => {
    const registerRootNamespace = removeWhiteSpace(serviceLineName);
    const registerGroupNamespace = removeWhiteSpace(serviceGroupName);
    const registerCategoryNamespace = removeWhiteSpace(serviceCategory.name);

    const regString = `${registerRootNamespace}.${registerGroupNamespace}.${registerCategoryNamespace}.priceAdjustment`;

    const registerServiceCategoryPriceAdjustmentInput = register(regString, {
      valueAsNumber: true,
      validate: (value) => {
        return Number.isNaN(value) ? translate("INVALID_VALUE") : true;
      },
      onBlur: async (e) => {
        const adjustmentValue = +getOnlyNumbers(e.target.value);
        if (adjustmentValue < GetMinPriceAdjustment()) {
          setValue(regString, GetMinPriceAdjustment(), {
            shouldValidate: true,
          });
        } else {
          updateServiceCategoryPriceAdjustment(regString, serviceCategory);
        }
      },
      value: serviceCategory.price_adjustment,
    });

    return (
      <Row className="bottom-border min-tr-height-lg" key={regString}>
        <Col className="pl-md p-sm" md={4}>
          {ts(serviceCategory.name)}
        </Col>
        <Col className="p-sm">
          {numberAsSwedishCurrency(GetTotalSuggestedPrice(serviceCategory))}
        </Col>
        <Col className="p-sm" md={3}>
          {serviceCategory.name === SERVICE_GROUP_CURRENT_ACCOUNTING ? (
            notAvailableInfoSpan
          ) : (
            <RHFInput
              disabled={
                !isDealEditable(currentOffer) ||
                offerLoadingStatus === "pending" ||
                !!serviceCategory.approved_by_email
              }
              register={registerServiceCategoryPriceAdjustmentInput}
              onChange={({ target }) => {
                setValue(regString, getOnlyNumbers(target.value), {
                  shouldValidate: true,
                });
              }}
              errorMessage={
                errors?.[registerRootNamespace]?.[registerGroupNamespace]?.[
                  registerCategoryNamespace
                ]?.priceAdjustment?.message
              }
              units="SEK"
            />
          )}
        </Col>
      </Row>
    );
  };

  const renderServiceDiscountRow = (
    serviceCategory: ServiceCategory,
    serviceGroupName: string,
    serviceLineName: string
  ) => {
    const registerRootNamespace = removeWhiteSpace(serviceLineName);
    const registerGroupNamespace = removeWhiteSpace(serviceGroupName);
    const registerCategoryNamespace = removeWhiteSpace(serviceCategory.name);

    const regStringDiscount = `${registerRootNamespace}.${registerGroupNamespace}.${registerCategoryNamespace}.discount`;
    const regStringDiscountComment = `${registerRootNamespace}.${registerGroupNamespace}.${registerCategoryNamespace}.discount_comment`;

    const registerServiceCategoryDiscountInput = register(regStringDiscount, {
      valueAsNumber: true,
      validate: (value) =>
        Number.isNaN(value) ? translate("INVALID_VALUE") : true,
      onBlur: () =>
        updateServiceCategoryDiscount(regStringDiscount, serviceCategory),
      value: serviceCategory.discount ?? 0,
    });

    const registerServiceCategoryDiscountCommentInput = register(
      regStringDiscountComment,
      {
        validate: (value) => {
          if (value === "" && getValues(regStringDiscount) > 0) {
            return "Kommentar krävs";
          }
          return true;
        },
        onBlur: () => {
          updateServiceCategoryDiscountComment(
            regStringDiscountComment,
            serviceCategory
          );
        },
        value: serviceCategory.discount_comment ?? "",
      }
    );

    return (
      <Row
        className="bottom-border min-tr-height-lg"
        key={`${regStringDiscount}InputKey`}
      >
        <Col md={5} className="p-sm pl-md">
          {serviceCategory.name === SERVICE_GROUP_CURRENT_ACCOUNTING ? (
            notAvailableInfoSpan
          ) : (
            <RHFInput
              disabled={
                !isDealEditable(currentOffer) ||
                offerLoadingStatus === "pending" ||
                !!serviceCategory.approved_by_email
              }
              register={registerServiceCategoryDiscountInput}
              onChange={({ target }) => {
                setValue(regStringDiscount, getOnlyNumbers(target.value), {
                  shouldValidate: true,
                });
              }}
              errorMessage={
                errors?.[registerRootNamespace]?.[registerGroupNamespace]?.[
                  registerCategoryNamespace
                ]?.discount?.message
              }
              units="SEK"
            />
          )}
        </Col>
        <Col className="pl-md p-sm">
          {serviceCategory.name === SERVICE_GROUP_CURRENT_ACCOUNTING ? (
            notAvailableInfoSpan
          ) : (
            <RHFInput
              disabled={
                !isDealEditable(currentOffer) ||
                offerLoadingStatus === "pending" ||
                !!serviceCategory.approved_by_email
              }
              register={registerServiceCategoryDiscountCommentInput}
              errorMessage={
                errors?.[registerRootNamespace]?.[registerGroupNamespace]?.[
                  registerCategoryNamespace
                ]?.discount_comment?.message
              }
            />
          )}
        </Col>
      </Row>
    );
  };

  const renderSuggestedPriceRow = (serviceCategory: ServiceCategory) => (
    <Row
      className="bottom-border min-tr-height-lg"
      key={`${serviceCategory.name}-SuggestedRow`}
    >
      <Col className="p-sm pl-md">
        {numberAsSwedishCurrency(serviceCategory.calculated_price)}
      </Col>
    </Row>
  );

  const renderFinalPriceRow = (serviceCategory: ServiceCategory) => (
    <Row
      className="bottom-border min-tr-height-lg"
      key={`${serviceCategory.name}FinalPriceKey`}
    >
      <Col className="p-sm pl-md">
        {numberAsSwedishCurrency(serviceCategory.final_price)}
      </Col>
    </Row>
  );

  const notAvailableInfoSpan = (
    <span className="not-available">{translate("NOT_AVAILABLE")}</span>
  );

  return (
    <div>
      <Modal
        header={<ModalHeader headerTitleText="COMMENT_MISSING" />}
        isOpen={showModalContent !== ""}
        onDismiss={() => setShowModalContent("")}
      >
        {showModalContent}
      </Modal>

      <Row>
        <Col className="price-column">
          <Row className="min-tr-height">
            <Col className="border-top bottom-border heading d-flex align-items-center pl-md">
              <div className="sub-h1">{translate("PRICE")}</div>
            </Col>
          </Row>
          <Row className="bottom-border min-tr-height">
            <Col className="pt-md pl-md" md={4}>
              <div className="fw-semibold">{translate("YEAR")}</div>
            </Col>
            <Col className="pt-md pl-sm" md={5}>
              <div className="fw-semibold">{translate("ESTIMATED_PRICE")}</div>
            </Col>
            <Col className="pt-md pl-sm" md={3}>
              <div className="fw-semibold">{translate("ADJUST_PRICE")}</div>
            </Col>
          </Row>
          {serviceAreas?.map((sa) =>
            sa.service_lines.map((sl) =>
              sl.service_groups.map((sg) =>
                sg.service_categories.map((sc) =>
                  renderPriceAdjustmentServiceRow(sc, sg.name, sl.name)
                )
              )
            )
          )}
          <Row className="min-tr-height">
            <Col className="p-sm pl-md" md={4}>
              <div className="fw-semibold">{translate("TOTAL_PRICE")}</div>
            </Col>
            <Col className="p-sm text-color-blue" md={5}>
              {numberAsSwedishCurrency(GetTotalSuggestedPrice())}
            </Col>
            <Col className="p-sm text-color-blue" md={3}>
              {numberAsSwedishCurrency(GetTotalAdjustments())}
            </Col>
          </Row>
        </Col>

        <Col className="suggested-price-column">
          <Row className="min-tr-height">
            <Col className="border-top bottom-border d-flex align-items-center pl-md heading">
              <div className="sub-h1">{translate("SUGGESTED_PRICE")}</div>
            </Col>
          </Row>
          <Row className="bottom-border min-tr-height">
            <Col className="pt-md pl-md">
              <div className="fw-semibold">{translate("PER_YEAR")}</div>
            </Col>
          </Row>
          {serviceAreas?.map((sa) =>
            sa.service_lines.map((sl) =>
              sl.service_groups.map((sg) =>
                sg.service_categories.map((sc) => renderSuggestedPriceRow(sc))
              )
            )
          )}
          <Row className="min-tr-height">
            <Col className="p-sm pl-md text-color-blue">
              {numberAsSwedishCurrency(GetCalculatedPrice())}
            </Col>
          </Row>
        </Col>

        <Col className="discount-column">
          <Row className="min-tr-height">
            <Col className="border-top bottom-border d-flex align-items-center pl-md heading">
              <div className="sub-h1">{translate("DISCOUNT")}</div>
            </Col>
          </Row>
          <Row className="bottom-border min-tr-height">
            <Col className="pt-md pl-md" md={5}>
              <div className="fw-semibold">{translate("DISCOUNT")}</div>
            </Col>
            <Col className="pt-md">
              <div className="fw-semibold">{translate("COMMENT")}</div>
            </Col>
          </Row>
          {serviceAreas?.map((sa) =>
            sa.service_lines.map((sl) =>
              sl.service_groups.map((sg) =>
                sg.service_categories.map((sc) =>
                  renderServiceDiscountRow(sc, sg.name, sl.name)
                )
              )
            )
          )}
          <Row className="min-tr-height">
            <Col className="p-sm pl-md text-color-blue">
              {numberAsSwedishCurrency(GetTotalDiscount())}
            </Col>
          </Row>
        </Col>

        <Col className="final-price-column">
          <Row className="min-tr-height">
            <Col className=" border-top bottom-border d-flex align-items-center pl-md heading">
              <div className="sub-h1">{translate("FINAL_PRICE")}</div>
            </Col>
          </Row>
          <Row className="bottom-border min-tr-height">
            <Col className="pt-md pl-md">
              <div className="fw-semibold">{translate("YEAR")}</div>
            </Col>
          </Row>
          {serviceAreas?.map((sa) =>
            sa.service_lines.map((sl) =>
              sl.service_groups.map((sg) =>
                sg.service_categories.map((sc) => renderFinalPriceRow(sc))
              )
            )
          )}
          <Row className="min-tr-height">
            <Col className="p-sm pl-md text-color-blue">{GetFinalPrice()}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
