/* eslint-disable no-continue */
/* eslint-disable camelcase */
import { Tab, TabList } from "@fluentui/react-components";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { uniqByFilter } from "libs/uniq-filter";
import {
  Service,
  ServiceCategory,
  ServiceGroupItem,
  getServiceCategoryFromEconomyServiceArea,
} from "models/offer/ServiceLine";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import {
  fetchLowestPossiblePrice,
  sendOfferForCalculation,
  updateServicesAndPostDealData,
} from "state/offer/offersThunks";
import { useAppDispatch } from "state/use-app-redux";
import "../Services/ServiceForm.scss";

import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import Tooltip from "components/tooltip/Tooltip";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { Frequency } from "models/offer/Frequency";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import { TOGGLE } from "constants/offer/input-form-types";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { ServiceValidity } from "models/offer/ServiceValidity";
import useSaveTaxObjectsData from "hooks/createNew/offer/use-save-tax-objects-data";
import { WizardSection } from "../../components/wizardSection";
import { useGetBreadcrumbs } from "../../components/wizardSection/useGetBreadcrumbs";
import { useRoutingForOffer } from "../../components/wizardSection/useRoutingForOffer";
import ServiceColumn, { ColSize } from "../components/service-column";
import ServiceFormWithTaxObjects from "../../components/serviceFormWithTaxObjects/serviceFormWithTaxObjects";
import { OfferRouteHelper } from "../../routes/offerRoutes";
import ServiceDetailsHeader, {
  FREQUENCY_ARRAY,
} from "../components/service-details-header";
import ServiceRow from "../components/service-row";
import {
  ServicesContext,
  TaxObjects,
} from "../Services/ServicesContextProvider";
import ServicesFormData from "../Services/ServicesFormData";
import "./currentAccounting.scss";
import {
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF,
} from "../consts/offer-contst";

export type CurrentAccountingForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  services: Service[];
};

type CurrentAccountingSmallRecurringProps = {
  serviceLine: string;
  serviceGroup: string;
  serviceCategory: string;
};

const defaultStartDate = getMonthsOfOneYear()[0];

export default function CurrentAccountingSmallRecurring({
  serviceLine,
  serviceGroup,
  serviceCategory,
}: CurrentAccountingSmallRecurringProps) {
  const { translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();

  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { updateServiceGroups, updateTaxObjects, taxObjects } =
    useContext(ServicesContext);

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  const user = useSelector((state: RootState) => state.users.currentUser);

  const { service_areas: serviceAreasFromOffer } = currentOffer;

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);
  const [showTaxObjects, setShowTaxObjects] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(true);

  const { validateNumericInput, validateTextInput, validateServices } =
    useValidateSmallRecurringCustomersServices();

  const backBtnLabel = `TAX_OBJECTS.BUTTON.GO_BACK_TO_${serviceGroup.toUpperCase()}`;

  const currentAccountingTemplate =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreas
    ) ?? new ServiceCategory();

  const currentAccountingInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreasFromOffer
    ) ?? new ServiceCategory();

  const serviceHeaders = uniqByFilter(
    currentAccountingTemplate.services.map((service) => service.header)
  );
  const servicesForHeader = (header: string) =>
    Service.sortServices(
      currentAccountingTemplate.services.filter(
        (service) => service.header === header
      )
    );

  const groupedServices: ServiceGroupItem[] = serviceHeaders
    .map((header) =>
      header !== ""
        ? {
            header,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sortingNumber: currentAccountingTemplate.services.find(
              (service) => service.header === header
            )!.pog_header_sorting_number,
            services: servicesForHeader(header),
          }
        : null
    )
    .filter((item): item is ServiceGroupItem => item !== null)
    .sort((a, b) => a.sortingNumber - b.sortingNumber);

  const [selectedHeader, setSelectedHeader] = useState(
    groupedServices[0]?.header
  );

  const {
    start_date,
    end_date,
    project_manager,
    frequency,
    year_end_year,
    year_end_month,
    services: servicesInOffer,
  } = currentAccountingInCurrentOffer;

  const [currentAccountingData, setCurrentAccountingData] =
    useState<CurrentAccountingForm>({
      startDate: start_date || defaultStartDate,
      endDate: end_date,
      projectManager: project_manager || user.email,
      yearEndYear: year_end_year,
      yearEndMonth: year_end_month || "",
      frequency: frequency || currentAccountingTemplate.frequency,
      services: [],
    });

  const activeServices = servicesInOffer.length
    ? servicesInOffer
    : currentAccountingTemplate.services.filter(
        (service) => service.input_form_type_state
      );
  const [currentAccountingServices, setCurrentAccountingServices] = useState<
    Service[]
  >([...activeServices]);

  const [servicesValidity, setServicesValidity] = useState<ServiceValidity[]>(
    []
  );

  const [isYearEndMonthValid, setIsYearEndMonthValid] = useState(
    !!currentAccountingData.yearEndMonth
  );

  useEffect(() => {
    setIsYearEndMonthValid(!!currentAccountingData.yearEndMonth);
  }, [currentAccountingData.yearEndMonth]);

  const { saveFormData } = useSaveTaxObjectsData({
    serviceCategoryInCurrentOffer: currentAccountingInCurrentOffer,
    formData: currentAccountingData,
    serviceCategoryServices: currentAccountingTemplate.services,
    setIsDirty,
    setCurrentAccountingServices,
  });

  const tableColumns = [
    { id: 1, title: translate("ON_OFF"), colSize: 0 },
    {
      id: 2,
      title: `${translate("SERVICE")} / ${translate("SETTINGS")}`,
      colSize: 1,
    },
    { id: 3, title: `${translate("VALUE")} *`, colSize: 2 },
    { id: 4, title: `${translate("FREQUENCY")} *`, colSize: 3 },
    { id: 5, title: translate("RESPONSIBLE"), colSize: 4 },
  ];

  const isValid = () => {
    return servicesValidity.every(
      (sv) => sv.isValueValid && sv.isFrequencyValid
    );
  };

  const getTaxObjects = (newTaxObjects?: TaxObjectService[]) => {
    switch (serviceGroup) {
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY:
        return newTaxObjects
          ? { smallRecurringMonthly: newTaxObjects }
          : taxObjects.smallRecurringMonthly;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF:
        return newTaxObjects
          ? { smallRecurringMonthlyEF: newTaxObjects }
          : taxObjects.smallRecurringMonthlyEF;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY:
        return newTaxObjects
          ? { smallRecurringQuarterly: newTaxObjects }
          : taxObjects.smallRecurringQuarterly;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF:
        return newTaxObjects
          ? { smallRecurringQuarterlyEF: newTaxObjects }
          : taxObjects.smallRecurringQuarterlyEF;
      default:
        return undefined;
    }
  };

  const allIncomeTaxServices = Service.getIncomeTaxServices(
    currentAccountingTemplate.services
  );

  useEffect(() => {
    const updatedTaxObjects = getTaxObjects() as TaxObjectService[];

    if (updatedTaxObjects) {
      dispatch(
        updateCurrentOffer({
          tax_objects: updatedTaxObjects,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taxObjects]);

  useEffect(() => {
    setServicesValidity(validateServices(currentAccountingServices));

    updateHeaderFrequency();

    // Sets the highest Frequency occurrence found in all the services.
    // "highest" in a way that it occurs more frequently.
    function updateHeaderFrequency() {
      let headerFreq = "";

      currentAccountingServices
        .map((service) => service.pog_service_frequency)
        .filter((serviceFreq) => serviceFreq)
        .forEach((serviceFreq) => {
          if (!headerFreq) {
            headerFreq = serviceFreq;
          } else {
            const headerFreqIndex = FREQUENCY_ARRAY.indexOf(
              headerFreq as Frequency
            );
            const serviceFreqIndex = FREQUENCY_ARRAY.indexOf(
              serviceFreq as Frequency
            );

            if (serviceFreqIndex < headerFreqIndex) {
              headerFreq = serviceFreq;
            }
          }
        });

      // The greatest allowed Frequency occurrence in the header is 'quarterly'.
      if (headerFreq === "yearly") {
        headerFreq = "quarterly";
      }

      // The lowest allowed Frequency occurrence in the header is 'monthly'.
      // 'monthly' is also the default value, should for some reason the value be empty.
      if (headerFreq === "weekly") {
        headerFreq = "monthly";
      }

      if (!headerFreq) {
        headerFreq = currentAccountingTemplate.frequency;
      }

      setCurrentAccountingData((data) => {
        if (data.frequency !== headerFreq) {
          return {
            ...data,
            frequency: headerFreq as Frequency,
          };
        }

        return data;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountingServices, currentAccountingTemplate.frequency]);

  const isServiceToggled = (service: Service) => {
    return currentAccountingServices.some(
      (toggledServices) =>
        toggledServices.service_matrix_id === service.service_matrix_id
    );
  };

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(true);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const updateContext = () => {
    const taxObjectsUpdate = getTaxObjects(
      currentOffer.tax_objects
    ) as TaxObjects;

    updateTaxObjects(taxObjectsUpdate);

    const serviceGroupToUpdate =
      getServiceLineWithCurrentAccountingData().service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === serviceGroup
      );
    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
  };

  const handleSave = async () => {
    await dispatch(
      updateServicesAndPostDealData(getServiceLineWithCurrentAccountingData())
    );

    updateContext();

    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const getServiceLineWithCurrentAccountingData = (
    data = currentAccountingData
  ) => {
    // Save the data to the service line in deal object
    const newCurrentAccountingData: ServiceCategory = {
      ...currentAccountingInCurrentOffer,
      project_manager: data.projectManager,
      start_date: data.startDate,
      frequency: data.frequency,
      year_end_year: data.yearEndYear,
      year_end_month: data.yearEndMonth,
      services: currentAccountingServices,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      serviceAreasFromOffer,
      newCurrentAccountingData
    );

    return { service_areas: newServiceAreas };
  };

  const updateServiceCategoryData = (
    formHeaderData?: Partial<CurrentAccountingForm>
  ) => {
    const newCurrentAccountingFormData = {
      ...currentAccountingData,
      ...formHeaderData,
    };
    setCurrentAccountingData(newCurrentAccountingFormData);
    const updatedServiceLine = getServiceLineWithCurrentAccountingData(
      newCurrentAccountingFormData
    );
    dispatch(updateCurrentOffer(updatedServiceLine));
    setIsDirty(true);
  };

  const renderServicesForHeader = (colSize: ColSize[]) => {
    const group = groupedServices.find((g) => g.header === selectedHeader);
    if (!group) {
      return;
    }

    /**
     * Iterates over services in the selected group, identifying the first
     * with an invalid value or, failing that, the first with an invalid frequency.
     *
     * Returns the matrix ID of the first invalid service, or undefined.
     */
    function getFirstInvalidServiceInputId() {
      for (const service of group!.services) {
        const serviceValidity = servicesValidity.find(
          (sv) => sv.serviceMatrixId === service.service_matrix_id
        );

        if (!serviceValidity) {
          continue;
        }

        const serviceWithValueIsPresent = currentAccountingServices.some(
          (s) =>
            s.header === group!.header &&
            isServiceToggled(s) &&
            s.input_form_type !== TOGGLE
        );

        if (serviceWithValueIsPresent) {
          if (!serviceValidity.isValueValid) {
            return service.service_matrix_id;
          }

          continue;
        }

        if (!serviceValidity.isFrequencyValid) {
          return service.service_matrix_id;
        }
      }
    }

    return (
      <Row>
        <Col className="px-lg">
          <Row className="fw-semibold mb-4">
            {tableColumns.map((column) => (
              <ServiceColumn colSize={colSize[column.colSize]} key={column.id}>
                {column.title}
              </ServiceColumn>
            ))}
          </Row>
          {group.services.map((service, index, services) => {
            const isLast = services.length === index + 1;

            const activeService = currentAccountingServices.find(
              (currService) =>
                currService.service_matrix_id === service.service_matrix_id
            );

            return (
              <ServiceRow
                key={`${service.header}${service.name}`}
                colSize={colSize}
                isLast={isLast}
                service={activeService || service}
                offerApprovedByEmail={
                  currentAccountingInCurrentOffer.approved_by_email
                }
                serviceIsInitiallyActive={
                  service.input_form_type_state && service.input_is_disabled
                }
                serviceIsToggled={isServiceToggled(service)}
                triggerInputFocus={
                  getFirstInvalidServiceInputId() === service.service_matrix_id
                }
                serviceGroup={serviceGroup}
                setIsDirty={setIsDirty}
                setCurrentAccountingServices={setCurrentAccountingServices}
                validateNumericInput={validateNumericInput}
                validateTextInput={validateTextInput}
              />
            );
          })}
        </Col>
      </Row>
    );
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          startDate: value,
        });
      }}
      startDate={currentAccountingData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={currentAccountingData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          frequency: value,
        });
      }}
      frequency={currentAccountingData.frequency}
      yearEndData={currentAccountingData}
      yearEndInCurrentOffer={currentAccountingInCurrentOffer}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          yearEndMonth: value,
        });
      }}
      showYearEndValidationMessage={!isYearEndMonthValid}
      requiredYearEndMonth
    />
  );

  const renderServiceDetailsForm = () => (
    <div className="current-accounting-content mb-3">
      <Row className="current-accounting-service-header align-items-center mb-lg">
        <Col md="auto">{translate("SERVICE_HEADER")}:</Col>
        <Col>
          {groupedServices.length > 0 && (
            <TabList
              className="p-0 fw-semibold"
              selectedValue={selectedHeader}
              onTabSelect={(e, data) => setSelectedHeader(data.value as string)}
            >
              {groupedServices.map((headerData) => {
                const { header } = headerData;

                const isRequired = servicesValidity.some(
                  (sv) =>
                    sv.serviceHeader === header &&
                    (!sv.isValueValid || !sv.isFrequencyValid)
                );

                return (
                  <Tooltip
                    content={`${isRequired ? "* " : ""}${header}`}
                    notTranslatable
                    childrenClassName="current-accounting-tab"
                    key={headerData.header}
                  >
                    <Tab
                      className="current-accounting-tab-content"
                      value={header}
                      key={header}
                    >
                      {`${isRequired ? "* " : ""}${header}`}
                    </Tab>
                  </Tooltip>
                );
              })}
            </TabList>
          )}
        </Col>
      </Row>

      {renderServicesForHeader([70, undefined, "3", "3", "2"])}
    </div>
  );

  const onBeforeNext = async (skipFetchLowestPrice = false) => {
    updateServiceCategoryData();
    await dispatch(sendOfferForCalculation());
    updateContext();
    if (
      currentOffer.new_customer &&
      currentOffer.customer?.legal_form &&
      !skipFetchLowestPrice
    ) {
      await dispatch(
        fetchLowestPossiblePrice({
          isNewCustomer: currentOffer.new_customer,
          companyType: currentOffer.customer.legal_form,
        })
      );
    }

    return true;
  };

  function renderServiceForm() {
    return (
      <WizardSection
        serviceName={ts(ACCOUNTING_SERVICE_LINE)}
        subtitles={[translate("CURRENT_ACCOUNTING_SUBTITLE")]}
        loadingStatus={loadingStatus}
        hideNavigation={showTaxObjects}
        nextButtonText={translate("SERVICE_PAGE.CALCULATE_PRICE_BUTTON")}
        isNextHidden={
          !currentAccountingServices.length ||
          !currentAccountingData.frequency ||
          !isValid() ||
          !isYearEndMonthValid
        }
        onBeforePrevious={handleOnBeforePrevious}
        onBeforeNext={onBeforeNext}
        hasDivider
        content={serviceFormHeader}
        headerShrink
      >
        <ServiceFormWithTaxObjects
          modalOpen={modalOpen}
          setOpenModal={setOpenModal}
          showServiceDetails={showServiceDetails}
          setShowServiceDetails={setShowServiceDetails}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          isValid={
            !!currentAccountingServices.length &&
            !!currentAccountingData.frequency
          }
          showTaxObjects={showTaxObjects}
          setShowTaxObjects={setShowTaxObjects}
          renderServiceDetailsForm={renderServiceDetailsForm}
          incomeTaxServices={allIncomeTaxServices}
          saveFormData={saveFormData}
          label={backBtnLabel}
        />
      </WizardSection>
    );
  }

  return edit ? (
    <Modal
      className="service-modal"
      isOpen={edit !== undefined}
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(true);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="fit-content-min"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="header-blue-stripe d-flex align-items-center">
              <Breadcrumb
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    ts(ACCOUNTING_SERVICE_LINE),
                    true
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit="SERVICE_PAGE.CALCULATE_PRICE_BUTTON"
          onSave={() => {
            onBeforeNext(true);
            navigate(OfferRouteHelper.getSummary());
          }}
          isDisabled={
            currentAccountingInCurrentOffer.services.length === 0 ||
            !isYearEndMonthValid
          }
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        <ServiceFormWithTaxObjects
          modalOpen={modalOpen}
          setOpenModal={setOpenModal}
          showServiceDetails={showServiceDetails}
          setShowServiceDetails={setShowServiceDetails}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          isValid={
            !!currentAccountingServices.length &&
            !!currentAccountingData.frequency
          }
          showTaxObjects={showTaxObjects}
          setShowTaxObjects={setShowTaxObjects}
          renderServiceDetailsForm={renderServiceDetailsForm}
          incomeTaxServices={allIncomeTaxServices}
          saveFormData={saveFormData}
          label={backBtnLabel}
        />
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}
