import { Radio, RadioGroup } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { Dropdown } from "components/dropdown";
import Switch from "components/switch";
import Tooltip from "components/tooltip/Tooltip";
import { TOGGLE } from "constants/offer/input-form-types";
import { useTranslation } from "hooks/use-translate";
import { Service } from "models/offer/ServiceLine";
import ServiceColumn, { ColSize } from "../service-column";
import { FREQUENCY_ARRAY } from "../service-details-header";
import ServiceRowValueField from "../service-row-value-field";
import FrequencyWarningDialog from "../frequency-warning/FrequencyWarningDialog";
import {
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF,
} from "../../consts/offer-contst";

interface ServiceRowProps {
  colSize: ColSize[];
  isLast: boolean;
  service: Service;
  serviceIsInitiallyActive: boolean;
  serviceIsToggled: boolean;
  offerApprovedByEmail: string;
  triggerInputFocus: boolean;
  serviceGroup?: string;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  setCurrentAccountingServices: React.Dispatch<React.SetStateAction<Service[]>>;
  validateNumericInput: (value: string, service: Service) => boolean;
  validateTextInput: (value: string, service: Service) => boolean;
}

export const prependWarningIcon = (text: string) => {
  return `⚠ ${text}`;
};

export default function ServiceRow({
  colSize,
  isLast,
  service,
  serviceIsInitiallyActive,
  serviceIsToggled,
  offerApprovedByEmail,
  triggerInputFocus,
  serviceGroup,
  setIsDirty,
  setCurrentAccountingServices,
  validateNumericInput,
  validateTextInput,
}: ServiceRowProps) {
  const { translate, ts } = useTranslation();
  const FIRM = "firm";
  const CUSTOMER = "customer";

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    trigger,
    resetField,
  } = useForm<Service>({
    mode: "all",
  });

  useEffect(() => {
    if (serviceIsToggled) {
      setValue("pog_service_frequency", service.pog_service_frequency);
    }
  }, [serviceIsToggled, service.pog_service_frequency, setValue]);

  useEffect(() => {
    if (serviceIsToggled) {
      trigger();
    } else {
      reset();
    }
  }, [serviceIsToggled, reset, trigger]);

  const [isInputFocused, setIsInputFocused] = useState(triggerInputFocus);

  const [isFrequencyModalOpen, setIsFrequencyModalOpen] = useState(false);
  const [pendingFrequencyValue, setPendingFrequencyValue] = useState<
    string | null
  >(service.pog_service_frequency);

  const frequencyOptions = FREQUENCY_ARRAY.map((freq) => {
    return {
      value: freq,
      label: ts(freq.toUpperCase()),
    };
  });

  useEffect(() => {
    if (serviceIsToggled) {
      setCurrentAccountingServices((currentServices) =>
        currentServices.map((s) => {
          if (
            s.service_matrix_id === service.service_matrix_id &&
            s.is_responsibility &&
            !s.responsible_party
          ) {
            return {
              ...service,
              responsible_party: s.responsible_default_is_firm
                ? FIRM
                : CUSTOMER,
            };
          }
          return s;
        })
      );
    }
  }, [serviceIsToggled, service, setCurrentAccountingServices]);

  const handleOnDiscard = () => {
    setIsFrequencyModalOpen(false);
    setPendingFrequencyValue("");
    updateService("pog_service_frequency", "");
    resetField("pog_service_frequency");
    trigger("pog_service_frequency");
  };

  const updateServiceState = (key: string, value: string | number) => {
    setIsDirty(true);

    setCurrentAccountingServices((currentServices) =>
      currentServices.map((s) => {
        if (s.service_matrix_id === service.service_matrix_id) {
          return {
            ...s,
            [key]: value,
          };
        }
        return s;
      })
    );
  };

  const shouldPopupWarningDialog = (value: string | number) => {
    switch (serviceGroup) {
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY:
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF:
        return value === "weekly" || value === "monthly";
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY:
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF:
        return value === "weekly";
      default:
        return false;
    }
  };

  const updateService = (key: string, value: string | number) => {
    if (key === "pog_service_frequency" && value) {
      if (shouldPopupWarningDialog(value)) {
        setPendingFrequencyValue(value.toString());
        setIsFrequencyModalOpen(true);
        // Blur the dropdown to close it
        const dropdownElement = document.activeElement as HTMLElement;
        dropdownElement?.blur();
        return;
      }
    }
    updateServiceState(key, value);
  };

  const serviceFreqConditionalOnFocus = () => {
    if (service.input_form_type !== TOGGLE) {
      return;
    }

    return isInputFocused;
  };

  const serviceFreqConditionalOnBlur = () => {
    if (service.input_form_type !== TOGGLE) {
      return;
    }

    setIsInputFocused(false);
  };

  const handleToggleService = (checked: boolean) => {
    setIsDirty(true);

    if (serviceIsToggled && !checked) {
      setCurrentAccountingServices((currentServices) =>
        currentServices.filter(
          (s) => s.service_matrix_id !== service.service_matrix_id
        )
      );
    } else {
      const activeService: Service = { ...service, units: 1 };
      setCurrentAccountingServices((currentServices) => [
        ...currentServices,
        activeService,
      ]);

      setIsInputFocused(true);
    }
  };

  return (
    <>
      <Row className="service-row align-items-center">
        <ServiceColumn colSize={colSize[0]}>
          <Tooltip
            content="CANT_EXCLUDE_SERVICE"
            enabled={serviceIsInitiallyActive}
          >
            <Switch
              disabled={!!offerApprovedByEmail || service.input_is_disabled}
              className="m-1"
              label=""
              checked={serviceIsToggled}
              onToggleMethod={(_, { checked }) => handleToggleService(checked)}
            />
          </Tooltip>
        </ServiceColumn>
        <ServiceColumn colSize={colSize[1]}>{service.name}</ServiceColumn>
        <ServiceColumn colSize={colSize[2]} className="value-col">
          <ServiceRowValueField
            service={service}
            serviceIsToggled={serviceIsToggled}
            updateService={updateService}
            validateNumericInput={validateNumericInput}
            validateTextInput={validateTextInput}
            isInputFocused={isInputFocused}
            onInputBlur={() => setIsInputFocused(false)}
          />
        </ServiceColumn>
        <ServiceColumn colSize={colSize[3]} className="frequency-col">
          {service.is_pog_service_frequency && (
            <Dropdown
              key={`${
                service.service_matrix_id
              }${serviceIsToggled}Frequency${getValues(
                "pog_service_frequency"
              )}`}
              className="px-0 mx-0"
              placeholder={translate("SELECT_FREQUENCY")}
              isFocused={serviceFreqConditionalOnFocus()}
              onBlur={serviceFreqConditionalOnBlur}
              disabled={!serviceIsToggled}
              value={service.pog_service_frequency || ""}
              onChange={(value) => {
                setValue("pog_service_frequency", value, {
                  shouldValidate: true,
                });

                updateService("pog_service_frequency", value);
              }}
              options={frequencyOptions}
              errorMessage={errors.pog_service_frequency?.message}
              formRegister={register("pog_service_frequency", {
                required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
              })}
            />
          )}
        </ServiceColumn>
        <ServiceColumn colSize={colSize[4]}>
          {service.is_responsibility && (
            <Tooltip
              content="CANT_CHANGE_RESPONSIBILITY"
              enabled={service.responsibility_input_is_disabled}
            >
              <RadioGroup
                layout="horizontal"
                onChange={(_, option) =>
                  updateService("responsible_party", option?.value ?? "")
                }
                value={service.responsible_party}
                disabled={
                  !serviceIsToggled || service.responsibility_input_is_disabled
                }
              >
                <Radio value={FIRM} label="Ludvig" />
                <Radio value={CUSTOMER} label={translate("CUSTOMER")} />
              </RadioGroup>
            </Tooltip>
          )}
        </ServiceColumn>
      </Row>

      {!isLast && <Row className="horizontal-divider" />}

      {isFrequencyModalOpen && (
        <FrequencyWarningDialog
          onDiscard={handleOnDiscard}
          onSave={() => {
            setIsFrequencyModalOpen(false);

            // Apply the saved frequency value
            if (pendingFrequencyValue !== null) {
              setValue("pog_service_frequency", pendingFrequencyValue, {
                shouldValidate: true,
              });
              updateServiceState(
                "pog_service_frequency",
                pendingFrequencyValue
              );
            }
          }}
        />
      )}
    </>
  );
}
